@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "MapoGoldenPier";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/MapoGoldenPier.woff2) format("woff2");
}
@font-face {
  font-family: "NanumMyeongjo";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/NanumMyeongjo.woff2) format("woff2");
}
@font-face {
  font-family: "NanumMyeongjo";
  font-weight: 700;
  src: url(./assets/fonts/NanumMyeongjoBold.woff2) format("woff2");
}
@font-face {
  font-family: "NanumMyeongjo";
  font-weight: 800;
  src: url(./assets/fonts/NanumMyeongjoExtraBold.woff2) format("woff2");
}

@font-face {
  font-family: "KPACJKSC";
  font-weight: 300;
  src: url(./assets/fonts/KPA_CJK_SC-Light.woff2) format("woff2");
}
@font-face {
  font-family: "KPACJKSC";
  font-weight: 500;
  src: url(./assets/fonts/KPA_CJK_SC-Medium.woff2) format("woff2");
}
@font-face {
  font-family: "KPACJKSC";
  font-weight: 700;
  src: url(./assets/fonts/KPA_CJK_SC-Bold.woff2) format("woff2");
}

body {
  margin: 0;
  font-family: "NanumMyeongjo" -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
select {
  /* appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none; */
  background: none;
  /* background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='12' viewBox='0 0 24 24' width='12' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"); */
  /* background-repeat: no-repeat; */
  /* background-position-x: 105%;
  background-position-y: 45%; */
  /* border: none;
  padding-right: 10px;
  outline: none; */
}
