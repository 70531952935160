@media print {
  .print-version .print-text-8 {
    font-size: 8px;
  }
  .print-version .print-text-xs {
    font-size: 12px;
  }
  .print-version .print-text-sm {
    font-size: 14px;
  }
  .print-version .print-text-base {
    font-size: 16px;
  }
  .print-version .print-text-lg {
    font-size: 18px;
  }
  .print-version .print-text-xl {
    font-size: 20px;
  }
  .print-version .print-text-50 {
    font-size: 50px;
  }
  .print-version .print-text-60 {
    font-size: 60px;
  }
  .print-version .print-text-80 {
    font-size: 80px;
  }
}
